<template>
  <div id="PatientR" class="w-full">
    <div class="vx-row">
      <feather-icon
        @click="back()"
        :icon="$vs.rtl ? 'ChevronRightIcon' : 'ChevronLeftIcon'"
        style="width: 30px; cursor: pointer"
      >
      </feather-icon>
      <p class="text-xl">
        {{ $t("PatientHistory") }}
      </p>
    </div>
    <vx-card>
      <vs-row vs-w="12" vs-justify="center" vs-align="center">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="1">

              <imageLazy
              :imageStyle="{ height: '40px', width: '40px' }"
              :imageClass="'rounded-full'"
              :src="baseURL + Patient.ProfileImagePath"
              placeHolderType="patient"
            />
         <!-- <h2>{{
           baseURL + Patient.ProfileImagePath
         }}
         </h2> -->
        </vs-col>
        <vs-col vs-type="flex" vs-justify="start" vs-align="start" vs-w="2">
          <p class="product-name font-medium truncate">
            {{ Patient.Name }}
          </p>
        </vs-col>
      </vs-row>
    </vx-card>
    <h3
      v-if="Model.length == 0"
      class="w-full sm:w-auto text-center text-primary mt-5"
    >
      {{ $t("NoDataToshow") }}
    </h3>

    <div v-if="Model.length > 0" style="margin: 10px; width: 85%; margin: auto">
      <div class="vx-row mt-5">
        <div class="vx-col lg:w-1/4">
          <h3>{{ $t("Reservation") }}</h3>
        </div>
        <div class="vx-col lg:w-2/5">
          {{ $t("ReservationDetail") }}
        </div>
      </div>
    </div>
    <div id="app">
      <div
        style="
          margin: 10px;
          display: flex;
          flex-direction: row;
          width: 85%;
          justify-content: center;
          margin: auto;
        "
        v-for="item in Model"
        :key="item.ID"
      >
        <div v-if="item.Patient" tabindex="0" class="w-full">
          <div class="vx-row mt-5">
            <div class="vx-col lg:w-1/4">
              <p>
                {{
                  item.DoctorSession.DateFormatted +
                  "  " +
                  item.DoctorSession.DateFrom.slice(11, 16)
                }}
              </p>
            </div>
            <div class="vx-col lg:w-2/5">
              <div class="mb-2 col-md-12">
                <div>
                  <a
                    class="linkClass"
                    @click="
                      showDownloadReports(
                        item.PatientID,
                        item.DoctorID,
                        item.ID
                      )
                    "
                  >
                    {{ $t("MedicalReports") }}
                  </a>
                </div>
                <div>
                  <a
                    @click="
                      showPatientDiagnosisPrescription(item.ID, item.PatientID)
                    "
                    class="linkClass"
                  >
                    {{ $t("PatientDiagnosisPrescription") }}
                  </a>
                </div>
              </div>
            </div>
            <div class="vx-col lg:w-2/5">
              <div
                style="justify-content: center"
                v-show="currentDate(item.DoctorSession.DateFrom)"
              >
                <div>
                  <p style="color: #bcbcbc">
                    {{ $t("NextOnlineAppointment") }}
                  </p>
                </div>
                <div>
                  <p style="color: #004477">
                    {{ item.DoctorSession.DateFormatted }}
                    at {{ item.DoctorSession.TimeFrom }}
                  </p>
                </div>
              </div>
            </div>
            <div class="borderClass"></div>
          </div>
        </div>
      </div>
    </div>
    <vs-popup title="" :active.sync="showDownloadReportsfield" style="" >
      <showDownloadReportsCom
        :patientDoc="patientDoc"

        v-if="showDownloadReportsfield"
        @closePop="showDownloadReportsfield = false"
      ></showDownloadReportsCom>
    </vs-popup>
  </div>
</template>
<script>
import showDownloadReportsCom from "@/views/doctorProfile/pendingMedicalReportsDownload.vue";
import moduleDoctor from "@/store/doctor/moduleDoctor.js";
import modulePatient from "@/store/Patient/modulePatient.js";
import { domain } from "@/gloabelConstant.js";
import imageLazy from "@/components/image_lazy.vue";
export default {
  components: {
    showDownloadReportsCom,
    imageLazy
  },
  data() {
    return {
      Patient: {},
      EnglishLan: false,
      patientDoc: {},
      showDownloadReportsfield: false,
      baseURL: domain,
      Model: [],
      Search: {
        PatientName: "",
      },
    };
  },
  computed: {
    patientReservation() {
      return this.$store.state.patientList.patientReservation;
    },
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    OpenUploadDocument(data) {
      this.ShowUploadDocument = true;
      this.showPendingMedicalReport = false;
      this.documentModel = data;
    },
    showPatientHistory(PatientID) {
      debugger;
      this.$router.push({
        name: "PatientMedicalHistorySession",
        params: { ID: PatientID },
      });
    },
    showDownloadReports(PatientID, DoctorID, PatientReservationSessionID) {
      debugger;
      this.patientDoc.PatientID = PatientID;
      this.patientDoc.DoctorID = DoctorID;
      this.patientDoc.PatientReservationSessionID = PatientReservationSessionID;
      this.showDownloadReportsfield = true;
    },

    showPatientDiagnosisPrescription(patientReservationSessionID, PatientID) {
      debugger;

      this.$router.push({
        name: "PatientDiagnosisPrescription",
        params: {
          patientReservationSessionID: patientReservationSessionID,
          patientID: PatientID,
        },
      });
    },
    currentDate(date) {
      debugger;
      const current = new Date();

      if (date < current) {
        debugger;
        return true;
        //Do your thing
      } else {
        debugger;
        return false;
      }
    },
    searchData(index) {
      debugger;
      if (this.$route.params.DoctorID) {
        var model = {};
        model.doctorID = this.$route.params.DoctorID;
        model.PatientID = this.$route.params.PatientID;
        model.NotIncludePaymentStatusIds=[1,3];
        model.PaymentStatusID=5; //paid

          //model.StatusID = 5;//completed
        if (index == 2) {
          if (this.Search.PatientName != "") {
            model.PatientName = this.Search.PatientName;
          }
        }
        this.$store
          .dispatch("DoctorList/getPatientReservation", model)
          .then((res) => {
            if (res.status == 200) {
              this.Model = res.data.Data;
              if (this.Model == null || this.Model.length == 0) {
                this.$vs.notify({
                  title: this.$t("NoData"),
                  text: this.$t("NoDataToshow"),
                  iconPack: "feather",
                  icon: "icon-alert-circle",
                  color: "warning",
                });
              }
            }
          })
          .catch(() => {
            this.$vs.loading.close();
          });
      }
      var x = this.Model.length;
      debugger;
    },
  },
  created() {
    this.EnglishLan =
      localStorage.getItem("SaveLang") == "en" ||
      localStorage.getItem("SaveLang") == null
        ? true
        : false;

    if (!moduleDoctor.isRegistered) {
      this.$store.registerModule("DoctorList", moduleDoctor);
      moduleDoctor.isRegistered = true;
    }
    if (!modulePatient.isRegistered) {
      this.$store.registerModule("patientList", modulePatient);
      modulePatient.isRegistered = true;
    }
    this.$store
      .dispatch("patientList/GetPatient", this.$route.params.PatientID)
      .then((res) => {
        debugger
        this.Patient = res.data.Data;
      });
    this.searchData(1);
  },
};
</script>
<style>
.searchTab {
  width: 600px;
  height: 50px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 10px #00000029;
  border-radius: 10px;
  opacity: 1;
}
#PatientR .inputText {
  border: 3px solid var(--unnamed-color-bcbcbc);
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 3px solid #bcbcbc;
  border-radius: 10px;
  opacity: 1;
  width: 200px;
  height: 40px;
}
#PatientR .linkClass {
  text-decoration: underline;
  font: normal normal normal 15px/22px Futura PT;
  letter-spacing: 0px;
  color: #454a62;
  text-transform: capitalize;
  opacity: 1;
}
#PatientR .borderClass {
  width: 100%;
  height: 2px;
  border: 1px solid #e7e7e7;
  opacity: 1;
}
#PatientR .stack-top {
  z-index: 9;
  border-radius: 5px;
  background: #202020;
}
#PatientR .head {
  color: white;
  text-align: center;
  margin-top: 20px;
}
[dir] #PatientR th,
[dir] #PatientR td {
  border: 1px solid #cfcfcf !important;
}
</style>
